<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <b-card>
                <h2>Group Membership Requests</h2>
                <a
                    class="card-link"
                    :href="mailtoAll"
                >
                    <font-awesome-icon
                        class="mr-1"
                        icon="envelope"
                    ></font-awesome-icon>
                    Email All
                </a>
                <router-link
                    :to="{ name: 'users' }"
                    class="card-link"
                >
                    <font-awesome-icon
                        class="mr-1"
                        icon="users"
                    ></font-awesome-icon>
                    Members
                </router-link>
                <router-link
                    :to="{ name: 'invite_group_members', params: { group_id: group.group_id } }"
                    class="card-link"
                >
                    <font-awesome-icon
                        icon="plus-circle"
                        class="mr-1"
                    ></font-awesome-icon>
                    Invite User
                </router-link>
            </b-card>
            <b-card
                class="mt-2"
                title="Filter"
                title-tag="h5"
            >
                <b-input placeholder="Search information..." v-model="filter"></b-input>
                <b-checkbox-group
                    class="mt-3"
                    v-model="show"
                >
                    <b-check
                        value="pending"
                    >
                        Pending
                    </b-check>
                    <b-check
                        value="approved"
                    >
                        Approved
                    </b-check>
                    <b-check
                        value="denied"
                    >
                        Denied
                    </b-check>
                </b-checkbox-group>
            </b-card>
        </b-skeleton-wrapper>
        <div
            class="scroll-x"
        >
            <b-table
                class="mt-2 w-100"
                :busy="loading"
                striped
                bordered
                hover
                :items="filteredRequests"
                :fields="fields"
                selectable
                select-mode="single"
                :filter="filter"
                :show-empty="true"
                empty-text="There are no results that match your criteria."
                empty-filtered-text="There are no results that match your criteria."
            >
                <template #cell(user.email)="{ item }">
                    <a :href="'mailto:' + item.user.email">{{ item.user.email }}</a>
                </template>
                <template #cell(actions)="{ item }">
                    <b-button
                        v-if="item.status == null"
                        variant="link"
                        @click="approveRequest(item)"
                        class="mr-2"
                    >
                        <font-awesome-icon icon="check"></font-awesome-icon>
                    </b-button>
                    <b-button
                        v-if="item.status == null"
                        variant="link"
                        @click="askDenyRequest(item)"
                    >
                        <font-awesome-icon icon="times"></font-awesome-icon>
                    </b-button>
                </template>
                <template #empty-text>
                    There are no records to fetch.
                </template>
            </b-table>
        </div>
    </b-container>
</template>
<script>
import { mapState } from "vuex";
export default {
    props: ["class_id"],
    data() {
        return {
            loading: false,
            selectedClass: {},
            show: [ "pending" ],
            filter: "",
            fields: [
                {
                    key         : "user.person.first_name",
                    label       : "First Name",
                    sortable    : true
                },
                {
                    key         : "user.person.last_name",
                    label       : "Last Name",
                    sortable    : true
                },
                {
                    key         : "user.email",
                    label       : "Email",
                    sortable    : true
                },
                {
                    key         : "actions",
                    label       : "Actions"
                }
            ],
            items: []
        };
    },
    mounted() {
        this.loadRequestList();
    },
    methods: {
        loadRequestList() {
            this.loading = true;
            this.$api.get(
                "/api/group/request/admin/list"
            ).then(({ data }) => {
                this.items   = data.records;
                this.loading = false;
            });
        },
        approveRequest({ id }) {
            this.$api.post("/api/group/request/admin/approve", {
                request_id: id 
            }).then(({ data }) => {
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.loadRequestList();
            })
        },
        askDenyRequest(user) {
            this.$bvModal.msgBoxConfirm(
                "Please confirm that you want to deny this user's request to access to your group's resources (Email: \"" +
                    user.email + "\").",
                {
                    title           : "Please confirm to deny user access",
                    size            : "sm",
                    buttonSize      : "sm",
                    okVariant       : "danger",
                    okTitle         : "YES",
                    cancelTitle     : "NO",
                    footerClass     : "p-2",
                    hideHeaderClose : false,
                    centered        : true
                }
            ).then(confirmed => {
                if (confirmed) {
                    this.denyRequest(user);
                }
            })
            .catch(err => {
                this.$bvToast.toast(err, {
                    title         : "Deny failed!",
                    variant       : "danger",
                    autoHideDelay : 5000,
                    appendToast   : true
                });
            });
        },
        denyRequest({ id }) {
            this.$api.post("/api/group/request/admin/deny", {
                request_id: id 
            }).then(({ data }) => {
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.loadRequestList();
            });
        }
    },
    computed: {
        mailtoAll() {
            return "mailto:" + this.items.map((user) => user.email).join(",");
        },
        filteredRequests() {
            return this.items.filter((request) => {
                console.log(request);
                if (request.status == 1)
                    return this.show.includes("approved");
                else if (request.status == 0)
                    return this.show.includes("denied");
                return this.show.includes("pending");
            });
        },
        ...mapState(["group"])
    }
  }
</script>
<style scoped>
    .scroll-x {
        max-width:  100%;
        overflow-x: scroll;
    }
</style>